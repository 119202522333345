import store from '@/store'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline, mdiCogOutline, mdiDatabaseOutline, mdiDnsOutline, mdiPencilOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default function useRoleList() {
    const headers = [
        { text: '名称', value: 'name' },
        { text: '代码', value: 'code' },
        { text: '描述', value: 'desc' },
        { text: '创建时间', value: 'create_at', sortable: false },
        // { text: '操作', value: 'actions', align: 'center', sortable: false }
    ]
    const param = ref({
        query: '',
        options: { sortBy: ['id'], sortDesc: [true], page: 1, itemsPerPage: 10 }
    })
    const table = ref({
        list: [],
        total: 0,
        snak: false,
        snakMsg: '',
        lodading: false
    })
    const fetchRoleList = () => {
        table.value.loading = true
        store.dispatch('app-system/fetchRoleList', param.value).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                loading.value = false
                return
            }
            const { list, total } = res.data.data
            table.value.list = list || []
            table.value.total = total
            table.value.loading = false
        }).catch(error => {
            console.log(error)
            table.value.loading = false
        })
    }

    const delRole = (id) => {
        table.value.loading = true
        store.dispatch('app-system/delRole', id).then(res => {
            const { code, msg } = res.data
            if (code !== 200) {
                console.log("error: ", code, msg);
                table.value = {
                    open: true,
                    msg: `错误码：${code}, ${msg}`
                }
                loading.value = false
                return
            }
            table.value.loading = false
            fetchRoleList()
        }).catch(error => {
            table.value.loading = false
        })
    }

    watch([param], () => {
        fetchRoleList()
    }, { deep: true })
    return {
        headers,
        param,
        table,
        fetchRoleList, delRole
    }
}
