var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-snackbar',{attrs:{"multi-line":"","light":_vm.$vuetify.theme.dark,"timeout":2000},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"error","text":""},on:{"click":function($event){_vm.table.open = false}}},'v-btn',attrs,false),[_vm._v(" 关闭 ")])]}}]),model:{value:(_vm.table.snak),callback:function ($$v) {_vm.$set(_vm.table, "snak", $$v)},expression:"table.snak"}},[_vm._v(" "+_vm._s(_vm.table.snakMsg)+" ")]),_c('role-add',{on:{"refetch-data":_vm.fetchRoleList},model:{value:(_vm.drawer.add),callback:function ($$v) {_vm.$set(_vm.drawer, "add", $$v)},expression:"drawer.add"}}),_c('role-update',{attrs:{"data":_vm.drawer.updateData},on:{"refetch-data":_vm.fetchRoleList},model:{value:(_vm.drawer.update),callback:function ($$v) {_vm.$set(_vm.drawer, "update", $$v)},expression:"drawer.update"}}),_c('v-card',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.table.list,"loading":_vm.table.loading,"options":_vm.param.options,"server-items-length":_vm.table.total,"show-select":""},on:{"update:options":function($event){return _vm.$set(_vm.param, "options", $event)}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openEdit(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.icons.mdiPencil))]),_c('span',[_vm._v("编辑")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.delRole(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))]),_c('span',[_vm._v("删除")])],1)],1)],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }