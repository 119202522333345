<template>
    <div id='user-list'>
        <v-snackbar v-model="table.snak" multi-line :light="$vuetify.theme.dark" :timeout="2000">
            {{ table.snakMsg }}
            <template #action="{ attrs }">
                <v-btn color="error" text v-bind="attrs" @click="table.open = false"> 关闭 </v-btn>
            </template>
        </v-snackbar>
        <role-add v-model='drawer.add' @refetch-data='fetchRoleList'></role-add>
        <role-update v-model='drawer.update' @refetch-data='fetchRoleList' :data="drawer.updateData"></role-update>
        <v-card>
            <!-- table -->
            <v-data-table :headers='headers' :items='table.list' :loading='table.loading' :options.sync='param.options'
                :server-items-length='table.total' show-select>
                <!-- actions -->
                <template #[`item.actions`]='{ item }'>
                    <v-menu bottom left>
                        <template v-slot:activator='{ on, attrs }'>
                            <v-btn icon v-bind='attrs' v-on='on'>
                                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="openEdit(item)">
                                <v-list-item-title>
                                    <v-icon class='me-2' size='20'>{{ icons.mdiPencil }}</v-icon>
                                    <span>编辑</span></v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="delRole(item.id)">
                                <v-list-item-title>
                                    <v-icon class='me-2' size='20'>{{ icons.mdiDeleteOutline }}</v-icon>
                                    <span>删除</span></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiDeleteOutline, mdiDotsVertical, mdiExportVariant, mdiPencil, mdiPlus, mdiSquareEditOutline, } from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import systemStoreModule from '../systemStoreModule'
import RoleAdd from './RoleAdd.vue'
import RoleUpdate from './RoleUpdate.vue'
import useRoleList from './useRoleList'

export default {
    components: { RoleAdd, RoleUpdate }, setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-system'
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, systemStoreModule)
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const { headers, param, table, fetchRoleList, delRole } = useRoleList()
        const drawer = ref({
            add: false,
            update: false,
            updateData: {}
        })

        const openEdit = (data) => {
            drawer.value.update = true
            drawer.value.updateData = data
        }

        fetchRoleList()

        return {
            headers,
            param,
            table,
            fetchRoleList, delRole, openEdit,
            drawer,
            // icons
            icons: { mdiSquareEditOutline, mdiPencil, mdiDotsVertical, mdiDeleteOutline, mdiPlus, mdiExportVariant, mdiAccountOutline, },
        }
    },
} </script>
<style lang='scss'> @import '@core/preset/preset/apps/user.scss';
</style>
